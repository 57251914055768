<template>
    <card :title="title" title-no-border class="border-radius overflow-hidden">
        <chart
            type="line"
            :height="400"
            ref="chat_block"
            :show-labels="false"
            :series="mainSerie"
            :options="options"
        />
    </card>
</template> 

<script>
import moment from 'moment';

export default {
    name: 'AdminDashboardChatBlock',

    props: {
        value: {
            type: Array,
            default: () => { return [] },
        },

        keyValue: {
            type: String,
            default: '',
        },
    },

    computed: {
        options() {
            return {
                scales: {},
                tooltips: {
                    callbacks: {
                        label: function(tooltipItems) { 
                            return tooltipItems.yLabel + ' €';
                        }
                    }
                },
            }
        },
        title() {
            return [...this.keyValue].map((item, i) => {
                if (i === 0) {
                    return item;
                }

                if (item.toUpperCase() === item) {
                    return ` ${item}`;
                }

                return item;
            }).join('');
        },

        datasets() {
            return [
                {
                    data: this.value.map(item => { return item.count }),
                    borderWidth: 5,
                    backgroundColor: 'transparent',
                    borderColor: '#3DA248',
                    // label: 'Count',
                    pointRadius: 2,
                    pointHitRadius: 10,
                    pointHoverRadius: 8,
                    pointHoverBackgroundColor: '#3DA248',
                    pointBackgroundColor: '#3DA248'
                }
            ];
        },

        labels() {
            return this.value.map(item => {
                return moment(item.date).format('ll');
            });
        },

        mainSerie() {
            return { datasets: this.datasets, labels: this.labels };
        },
    },
}
</script>